import urlJoin from 'url-join';
import { imageLoader } from 'next-image-loader/image-loader';
import { APP_DASHBOARD_STATIC_CF_DOMAIN } from './constants';

const S3_BASE_URL = process.env.NEXT_PUBLIC_SERVICE_CORE__S3_PUBLIC_BASE_URL;

imageLoader.loader = ({ src, width, quality }) => {
  if (!src.startsWith(S3_BASE_URL)) {
    return urlJoin(
      '/_next/image',
      `?url=${encodeURIComponent(src)}`,
      `&w=${width}`,
      `&q=${quality || 75}`
    );
  }

  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(',');

  return urlJoin(
    APP_DASHBOARD_STATIC_CF_DOMAIN,
    '/cdn-cgi/image/',
    paramsString,
    src
  );
};
