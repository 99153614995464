export * from './helpscout';

export const DEFAULT_PLATFORM_URL = 'https://core.service.elfsight.com';
export const PLATFORM_SCRIPT_URL =
  'https://static.elfsight.com/platform/platform.js';

export const APP_DASHBOARD_STATIC_CF_DOMAIN =
  'https://universe-static.elfsightcdn.com';

// https://developers.facebook.com/apps/309386470786326
export const FACEBOOK_APP_ID = '309386470786326';

export const REQUEST_WIDGET_URL =
  'https://community.elfsight.com/c/wishlist/new-app-request/30';

export const DISABLED_SHARE_LINK_APP_ALIASES = [
  'banner',
  'contact-form',
  'form-builder',
  'popup',
  'subscription-form',
  'slider'
];

export const ACCOUNT_PID_SESSION_OPTION = 'elfsight-account-pid';
